<template>
    <b-container fluid>
        <b-row>
            <b-col cols="12">
                <h2 class="text-center my-4">Novi MPC obrazac</h2>
            </b-col>
            <b-col cols="12" class="flex-center">
                <b-form v-on:submit.prevent="submitForm" class="w-75">
                    <b-form-group
                            :label-cols-md="4"
                            label="Izaberite dobavljača:"
                            label-for="associate">
                        <select v-if="associates"
                                name="associate_id"
                                class="form-control form-control-sm custom-select custom-select-sm"
                                required>
                            <option value="" selected disabled>-- Molimo odaberite dobavljača --</option>
                            <option v-for="associate in associates.suppliers" :value="associate.id">{{associate.name}}</option>
                            <!-- <option v-for="associate in associates.buyers" :value="associate.id">{{associate.name}}</option> -->
                        </select>
                    </b-form-group>
                    <b-form-group
                            :label-cols-md="4"
                            label="Broj računa:"
                            label-for="invoice_number">
                        <b-form-input id="invoice_number"
                                      name="invoice_number"
                                      required
                                      type="text"
                                      size="sm"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group
                            :label-cols-md="4"
                            label="Datum računa:"
                            label-for="invoice_date">
                        <date-picker format="DD.MM.YYYY"
                                     v-model="invoice_date"
                                     :lang="$parent.hrv"
                                     :first-day-of-week="1"
                                     class="w-100"
                                     :input-attr="{required: true}"
                                     id="invoice_date">
                        </date-picker>
                    </b-form-group>
                    <b-form-group
                            :label-cols-md="4"
                            label="Broj obrasca:"
                            label-for="form_number">
                        <b-form-input id="form_number"
                                      name="form_number"
                                      required
                                      type="text"
                                      size="sm"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group
                            :label-cols-md="4"
                            label="Datum obrasca:"
                            label-for="form_date">
                        <date-picker format="DD.MM.YYYY"
                                     v-model="form_date"
                                     :lang="$parent.hrv"
                                     :first-day-of-week="1"
                                     class="w-100"
                                     :input-attr="{required: true}"
                                     id="form_date">
                        </date-picker>
                    </b-form-group>
                    <b-form-group :label-cols-md="4">
                        <b-form-radio-group
                                id="variant"
                                name="variant"
                                v-model="variant">
                            <b-form-radio value="0">Prijemni list</b-form-radio>
                            <b-form-radio value="1">Povratnica</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                    <b-btn variant="primary" type="submit">Potvrdi</b-btn>
                </b-form>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: 'CreateMpcForm',
        data () {
            return {
                invoice_date: null,
                form_date: null
            }
        },
        computed: {
            associates: function () {
                return this.$store.getters.associates;
            }
        },
        methods: {
            submitForm: function (e) {
                const formData = new FormData(e.target);
                formData.append('client_id', this.$parent.selectedClient.id);
                formData.append('invoice_date', this.$moment(this.invoice_date).format("YYYY-MM-DD"));
                formData.append('form_date', this.$moment(this.form_date).format("YYYY-MM-DD"));
                axiOsta.post(api.mpcForms, formData).then(response => {
                    if(response.status === 201) {
                        this.$router.push('/mpc_forms/' + response.data.id);
                    }
                });
            },
        }
    }


</script>
